const NextSvg = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 24C31.5 24.5402 31.2095 25.0387 30.7395 25.3051L22.8593 29.7705C21.3593 30.6205 19.5 29.537 19.5 27.813V20.1872C19.5 18.4632 21.3593 17.3797 22.8593 18.2296L30.7395 22.695C31.2095 22.9613 31.5 23.4598 31.5 24ZM24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6ZM9 24C9 15.7157 15.7157 9 24 9C32.2843 9 39 15.7157 39 24C39 32.2843 32.2843 39 24 39C15.7157 39 9 32.2843 9 24Z"
        fill="#0038D2"
      />
    </svg>
  );
};

export default NextSvg;

const RightArrow = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.2718 16.0002L14.9503 27.6361C14.5651 28.032 14.5738 28.6651 14.9696 29.0502C15.3655 29.4354 15.9986 29.4267 16.3837 29.0309L28.3837 16.6975C28.7614 16.3093 28.7614 15.691 28.3837 15.3028L16.3837 2.96947C15.9986 2.57364 15.3655 2.56496 14.9696 2.9501C14.5738 3.33524 14.5651 3.96834 14.9503 4.36418L26.2718 16.0002Z"
        fill="#0038D2"
      />
    </svg>
  );
};

export default RightArrow;

const PrevSvg = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 24C16.5 23.4598 16.7905 22.9613 17.2605 22.6949L25.1407 18.2295C26.6407 17.3795 28.5 18.463 28.5 20.187L28.5 27.8128C28.5 29.5368 26.6407 30.6203 25.1407 29.7704L17.2605 25.305C16.7905 25.0387 16.5 24.5402 16.5 24ZM24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42ZM39 24C39 32.2843 32.2843 39 24 39C15.7157 39 9 32.2843 9 24C9 15.7157 15.7157 9 24 9C32.2843 9 39 15.7157 39 24Z"
        fill="#0038D2"
      />
    </svg>
  );
};

export default PrevSvg;
